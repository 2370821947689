import { selectedCycleIdState } from 'modules/pond/stores/atom'
import { FC, useLayoutEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

const PondClearStateWrapper: FC = ({ children }) => {
  const [cycleIdRecoilState, setCycleIdRecoilState] = useRecoilState(selectedCycleIdState)
  const [cycleId, setCycleId] = useState(cycleIdRecoilState)

  useLayoutEffect(() => {
    if (cycleId) {
      setCycleIdRecoilState(null)
      setCycleId(null)
    }
  }, [])

  if (cycleId) return null

  return <>{children}</>
}

export default PondClearStateWrapper
