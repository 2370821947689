import { FeatureKey } from 'common/constants/permission'
import { PondTab } from 'modules/pond/constants'

export const convertFeatureKeyToPondTab = (key: FeatureKey): PondTab => {
  switch (key) {
    case FeatureKey.Food:
      return PondTab.Food
    case FeatureKey.Growth:
      return PondTab.Growth
    case FeatureKey.WaterQuality:
    case FeatureKey.WaterMineral:
    case FeatureKey.WaterVibrio:
      return PondTab.Water
    case FeatureKey.Death:
      return PondTab.DeadShrimp
    case FeatureKey.Medicine:
      return PondTab.Medicine
    case FeatureKey.Cost:
      return PondTab.Cost
    case FeatureKey.Sales:
      return PondTab.Sales
    case FeatureKey.Cycle:
      return PondTab.Setting
    case FeatureKey.Dashboard:
    default:
      return PondTab.Dashboard
  }
}
