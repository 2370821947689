export enum FeatureKey {
  User = 'user',
  Farm = 'farm',
  Pond = 'pond',
  Cycle = 'cycle',
  Dashboard = 'dashboard',
  Food = 'food',
  Growth = 'growth',
  Death = 'dead_shrimp',
  WaterQuality = 'water_quality',
  WaterMineral = 'water_mineral',
  WaterVibrio = 'water_vibrio',
  Medicine = 'medicine',
  Cost = 'cost',
  Sales = 'sales',
  PdfSummary = 'pdf_summary',
  PdfDaily = 'pdf_daily',
  PdfFeatures = 'pdf_features',
}

export enum CRUDAction {
  Create = 0,
  Read = 1,
  Update = 2,
  Delete = 3,
}

export const FREE_MODULES = [FeatureKey.Farm, FeatureKey.Pond, FeatureKey.Cycle, FeatureKey.User]
export const TAB_MODULES = [
  FeatureKey.Dashboard,
  FeatureKey.Food,
  FeatureKey.Growth,
  FeatureKey.Growth,
  FeatureKey.Death,
  FeatureKey.WaterQuality,
  FeatureKey.WaterMineral,
  FeatureKey.WaterVibrio,
  FeatureKey.Medicine,
  FeatureKey.Cost,
  FeatureKey.Sales,
  FeatureKey.Cycle,
]
