export enum RoleName {
  PrimaryOwner = 'เจ้าของฟาร์ม',
  SecondaryOwner = 'รองเจ้าของฟาร์ม',
  Admin = 'แอดมิน',
}

export enum RoleType {
  Custom = 'custom',
  Default = 'default',
}
