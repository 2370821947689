import { useQuery } from '@tanstack/react-query'
import { getShrimpSalesKey, getShrimpSalesSummaryKey } from 'common/constants/queryKeys'
import useCycleApi from 'common/hooks/useCycleApi'
import { BaseResponse } from 'common/types/api'
import { formatDate } from 'common/utils/date'
import { selectedCycleIdState } from 'modules/pond/stores/atom'
import { useRecoilValue } from 'recoil'

import { getSalesTypes, SALES_REQUEST_CONFIG } from '.'
import { shrimpSalesDateTableFilterState } from '../stores/atom'
import { ShrimpSales, ShrimpSalesSummary, Partial } from './types'
import { usePermitByFeature } from 'modules/user/api/selector'
import { FeatureKey } from 'common/constants/permission'

export const useGetShrimpSales = () => {
  const cycleApi = useCycleApi()
  const date = useRecoilValue(shrimpSalesDateTableFilterState)
  const cycleId = useRecoilValue(selectedCycleIdState)
  const initParams = {
    params: {
      date: date ? formatDate(date) : '',
    },
    cycleId,
  }

  return useQuery({
    queryKey: getShrimpSalesKey(cycleId || '', date),
    queryFn: async ({ pageParam = initParams }) => {
      const { data } = await cycleApi.request<BaseResponse<ShrimpSales[]>>({
        ...SALES_REQUEST_CONFIG.get,
        ...pageParam,
      })
      return data.data
    },
    staleTime: Infinity,
    enabled: !!cycleId,
  })
}

export const useShrimpSalesSummary = () => {
  const cycleApi = useCycleApi()
  const cycleId = useRecoilValue(selectedCycleIdState)
  
  return useQuery({
    queryKey: getShrimpSalesSummaryKey(cycleId || ''),
    queryFn: async () => {
      const { data } = await cycleApi.request<BaseResponse<ShrimpSalesSummary>>(
        SALES_REQUEST_CONFIG.getSummary
      )
      return data.data
    },
    staleTime: Infinity,
    enabled: !!cycleId,
  })
}

export const useGetShrimpSalesTypes = () => {
  return useQuery({
    queryKey: ['sales-types'],
    queryFn: async () => {
      const { data } = await getSalesTypes()
      return data.data
    },
    staleTime: Infinity,
  })
}

export const useGetPartials = () => {
  const cycleApi = useCycleApi()
  const cycleId = useRecoilValue(selectedCycleIdState)
  const isPermit = usePermitByFeature(FeatureKey.Sales)

  return useQuery({
    queryKey: ['partials', cycleId],
    queryFn: async () => {
      const { data } = await cycleApi.request<BaseResponse<Partial[]>>(
        SALES_REQUEST_CONFIG.getPartials
      )
      return data.data
    },
    staleTime: Infinity,
    enabled: isPermit && !!cycleId
  })
}
