import { getLocalStorage } from 'common/utils/localstorage'
import { useMutateLogout } from 'modules/auth/api/mutation'
import { REFRESH_TOKEN_KEY } from 'modules/auth/constants'
import { useIsPrimaryOwnerAndAdmin } from 'modules/user/api/selector'
import { selectedUserInfoState } from 'modules/user/stores/atom'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// hooks
import { useRecoilValue } from 'recoil'
// routes
import { PATH_DASHBOARD } from 'routes/paths'

import { Box, MenuItem, Typography } from '@mui/material'
// material
import { useTheme } from '@mui/material/styles'

import { Icon } from '@iconify/react'

// components
import { MIconButton } from 'components/@material-extend'
import MenuPopover from 'components/MenuPopover'
import MyAvatar from 'components/MyAvatar'

export default function AccountPopover() {
  const { t } = useTranslation('common')
  const userInfo = useRecoilValue(selectedUserInfoState)
  const navigate = useNavigate()
  const anchorRef = useRef(null)
  const theme = useTheme()
  const logout = useMutateLogout()
  const isOwner = useIsPrimaryOwnerAndAdmin()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    const rf = getLocalStorage(REFRESH_TOKEN_KEY)
    await logout.mutateAsync(rf)
  }

  const MENU_OPTIONS = [
    {
      name: 'profile',
      label: t('profile'),
      icon: 'lets-icons:user-duotone',
      onClick: () => navigate(PATH_DASHBOARD.general.profile),
    },
    {
      name: 'feedback',
      label: t('feedback'),
      icon: 'material-symbols:feedback',
      color: theme.palette.info.main,
      onClick: () => navigate(PATH_DASHBOARD.general.feedback),
    },
    ...(isOwner
      ? [
          {
            name: 'phone',
            label: t('phone'),
            icon: 'mdi:link-box-variant',
            color: theme.palette.primary.main,
            onClick: () => navigate(PATH_DASHBOARD.general.changePhone),
          },
          {
            name: 'password',
            label: t('password'),
            icon: 'mdi:link-box-variant',
            color: theme.palette.primary.main,
            onClick: () => navigate(PATH_DASHBOARD.general.resetPassword),
          },
        ]
      : []),
    {
      name: 'logout',
      label: t('logout'),
      icon: 'material-symbols:logout',
      onClick: handleLogout,
      color: theme.palette.error.main,
    },
  ]

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{ borderRadius: 1, justifyContent: 'flex-start', minWidth: 150 }}
      >
        <MyAvatar />
        <Box ml={2}>
          <Typography fontWeight="bold" fontSize={14} color={theme.palette.grey[900]}>
            {userInfo?.name}
          </Typography>
        </Box>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ minWidth: '166px', m: 0, py: 2 }}
        isShowArrow={false}
      >
        {MENU_OPTIONS.map((option) => (
          <>
            <MenuItem
              key={option.label}
              onClick={option.onClick}
              sx={{
                px: 2,
                typography: 'body2',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  component={Icon}
                  icon={option.icon}
                  color={option.color}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography variant="body2">{option.label}</Typography>
              </Box>
            </MenuItem>
          </>
        ))}
      </MenuPopover>
    </>
  )
}
