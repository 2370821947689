import { FeatureKey, TAB_MODULES } from 'common/constants/permission'
import uniq from 'lodash/uniq'
import { RoleName } from 'modules/member/constants'

import { convertFeatureKeyToPondTab } from 'utils/pond_tabs'

import { useUserInfo } from './queries'

export const useAllowFeatures = () => {
  const { data } = useUserInfo()
  const features = (data?.user_role.permissions ?? []).filter((el) => {
    const isRead = el.crud_permission[1]
    return isRead
  })
  return features
}

export const useAllowPondTabs = () => {
  const features = useAllowFeatures()
  const featuresInTabs = features?.filter(
    (el) => TAB_MODULES.includes(el.feature.key as FeatureKey) && el.crud_permission[1] === 1
  )

  if (featuresInTabs.length > 0) {
    const results = featuresInTabs.map((el) =>
      convertFeatureKeyToPondTab(el.feature.key as FeatureKey)
    )
    return uniq(results)
  }
  return []
}

export const usePermitByFeature = (key: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => p.feature.key === key)
  return feature?.crud_permission[1] ? true : false
}

export const usePermitByFeatures = (crud: number, keys: string[]) => {
  const { data } = useUserInfo()
  const features = (data?.user_role.permissions ?? []).filter((p) => keys.includes(p.feature.key))
  const allow = features.every((f) => f.crud_permission[crud] === 1)
  return allow
}

export const usePermitSomeFeatures = (keys: string[]) => {
  const { data } = useUserInfo()
  const features = (data?.user_role.permissions ?? []).filter((p) => keys.includes(p.feature.key))
  const allow = features.some((f) => f.crud_permission[1] === 1)
  return allow
}

export const usePermitToActionFeature = (actions: number[], featureKey: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => featureKey === p.feature.key)
  const allow = actions.every((a) => feature?.crud_permission[a] === 1)
  return allow
}

export const usePermitSomeActionToFeature = (actions: number[], featureKey: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => featureKey === p.feature.key)
  const allow = actions.some((a) => feature?.crud_permission[a] === 1)
  return allow
}

export const useGetCRUDByFeature = (featureKey: string) => {
  const { data } = useUserInfo()
  const feature = (data?.user_role.permissions ?? []).find((p) => p.feature.key === featureKey)
  return feature?.crud_permission
}

export const useIsPrimaryOwnerAndAdmin = () => {
  const { data } = useUserInfo()

  return data?.user_role.name === RoleName.PrimaryOwner || data?.user_role.name === RoleName.Admin
}
