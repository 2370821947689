import { BaseResponse, MessageResponse } from 'common/types/api'
import axios from 'core/api'
import { SendOTPBody, VerifyOTPBody, VerifyOTPResponse } from 'modules/auth/api/types'

import {
  ChangePhoneNumberRequest,
  EditUserInfoRequest,
  FeedbackRequest,
  UserInfoResponse,
} from './types'

export const getUserInfo = () => axios.get<BaseResponse<UserInfoResponse>>('/user')

export const editUserInfo = (body: EditUserInfoRequest) =>
  axios.patch<MessageResponse>('/user', body)

export const uploadProfilePicture = (data: FormData) =>
  axios.post<BaseResponse<string>>('/user/profile-image', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const createFeedback = (body: FeedbackRequest) =>
  axios.post<MessageResponse>('/user/feedback', body)

export const sendOTPChangePhone = (body: SendOTPBody) =>
  axios.post<MessageResponse>('/phone-number/send-sms-otp', body)

export const verifyOTPChangePhone = (body: VerifyOTPBody) =>
  axios.post<BaseResponse<VerifyOTPResponse>>('/phone-number/verify-sms-otp', body)

export const changePhoneNumber = (body: ChangePhoneNumberRequest) =>
  axios.patch<MessageResponse>('/phone-number', body)
